



















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { ComponentType } from '@/models'
import * as ComponentTypes from '@/components/componentTypes'
import { GraphQLError } from 'graphql'
import { Route } from 'vue-router'

@Component({})
export default class AdminComponentsEdit extends Vue {
  /** Current Environment ID */
  @Prop({ type: String, required: true }) environmentId!: string
  /** Current component type name */
  @Prop({ type: String, required: true }) componentTypeName!: string
  /** Current component ID */
  @Prop({ type: String, required: true }) componentId!: String

  /** Component Name */
  displayName = ''

  currentTab = 'component'

  additionalTabs: Record<string, string> = {}

  @Watch('componentTypeName')
  clearAdditionalTabs(cType: string, prevCtype: string) {
    if (cType !== prevCtype) {
      this.$set(this, 'additionalTabs', {})
      this.currentTab = 'component'
    }
  }

  get hasAdditionalTabs() {
    return Object.keys(this.additionalTabs).length
  }

  updateAdditionalTabs(additionalTabs: Record<string, string>) {
    this.$set(this, 'additionalTabs', additionalTabs)
  }

  updateName(name: string) {
    this.displayName = name
  }

  async handleDelete() {
    await this.$store.dispatch('snackbar/showSnackbar', {
      text: `${this.componentType.titleSingle} eliminado con éxito!`
    })
    this.$emit('deleteComponent', true)
    return this.$router.go(-1)
  }

  async handleSave() {
    await this.$store.dispatch('snackbar/showSnackbar', {
      text: `${this.componentType.titleSingle} actualizado con éxito!`
    })
  }

  async handleError(e: any) {
    await this.$store.dispatch('snackbar/showSnackbar', {
      text: 'Error: ' + e.message,
      color: 'error'
    })
  }

  /** Current component type */
  get componentType(): ComponentType<any> {
    return (ComponentTypes as Record<string, ComponentType<any>>)[
      this.componentTypeName
    ]
  }

  get mystyle() {
    if (this.hasAdditionalTabs) {
      return {
        height: 'calc(100% - 48px)',
        'background-color': 'transparent'
      }
    } else {
      return {
        height: '100%',
        'background-color': 'transparent'
      }
    }
  }
}
